import React, { useContext, useRef } from 'react';
import { TableRow, TableCell, useTheme, SxProps as MuiSxProps, Chip } from '@mui/material';
import { TreeContext } from '../SearchableTree';
import Icon from '../../Icon';
import { compareItems, instanceOfTreeDeviceFE, TreeDeviceFE, TreeDeviceUnassignedFE } from '../../../../model/frontendDataModels';
import { useOnScreen } from '../../../../hooks/useOnScreen';
import { selectDevice } from './selectDevice';
import Store from '../../../../store/Store';
import moment from 'moment-timezone';

type Props = {
  treeDevice?: TreeDeviceFE | TreeDeviceUnassignedFE;
  deviceList: (TreeDeviceFE | TreeDeviceUnassignedFE)[];
  unassigned: boolean;
};

export default function DeviceTableRow({ treeDevice, deviceList, unassigned }: Props): JSX.Element | null {
  const theme = useTheme();
  const { state } = useContext(Store);
  const treeContext = useContext(TreeContext);
  const headers = treeDevice === undefined;

  const componentRef = useRef<HTMLTableRowElement>(null);

  const narrowWidths = unassigned ? ['30%', '30%', '40%'] : ['40%', '40%', '20%'];
  const wideWidths = unassigned ? ['20%', '22%', '18%', '21%', '24%', '24%'] : ['20%', '20%', '20%', '22%', '15%', '15%'];

  if (headers) {
    const sxBase = theme.typography.columnHeader;

    const sx: MuiSxProps = {
      ...sxBase,
      border: 'none',
      overflow: 'hidden',
      textOverflow: 'clip',
      userSelect: 'none',
    };
    const warningSx: MuiSxProps = {
      ...sx,
      color: theme.palette.error.main,
    };

    return (
      <TableRow>
        <TableCell width={treeContext.wideMode ? wideWidths[0] : narrowWidths[0]} sx={sx}>
          {'Device ID'}
        </TableCell>
        {treeContext.wideMode && (
          <TableCell width={treeContext.wideMode ? wideWidths[1] : narrowWidths[0]} sx={sx}>
            {'Serial No'}
          </TableCell>
        )}
        <TableCell width={treeContext.wideMode ? wideWidths[2] : narrowWidths[1]} sx={sx}>
          {'Type'}
        </TableCell>
        {treeContext.wideMode && (
          <>
            <TableCell width={wideWidths[3]} sx={sx}>
              {'Last seen'}
            </TableCell>
            {!unassigned && (
              <TableCell width={wideWidths[4]} sx={sx}>
                {'State'}
              </TableCell>
            )}
          </>
        )}
        <TableCell width={treeContext.wideMode ? wideWidths[5] : narrowWidths[2]} sx={unassigned ? sx : warningSx}>
          {unassigned ? 'Last seen by' : ''}
        </TableCell>
      </TableRow>
    );
  } else {
    const sxBase = theme.typography.deviceInfoText;
    const isSelected = treeContext.selectedTreeItems.findIndex(item => compareItems(item, treeDevice)) !== -1;
    const lastSeenDate = moment(treeDevice.lastSeen, moment.ISO_8601).tz(state.timezone);
    const lastSeen = lastSeenDate.format('YYYY-MM-DD HH:mm');

    const isOnScreen = useOnScreen(componentRef);

    React.useEffect(() => {
      if (componentRef && componentRef.current && isSelected && !isOnScreen) {
        componentRef.current.scrollIntoView({
          behavior: 'smooth',
        });
      }
    }, [treeContext.selectedTreeItems]);

    const sx: MuiSxProps = {
      ...sxBase,
      border: 'none',
      overflow: 'hidden',
      textOverflow: 'clip',
      userSelect: 'none',
      ...(isSelected && {
        backgroundColor: theme.palette.info.main,
        color: theme.palette.action.selected,
      }),
      ...(treeContext.canSelect(treeDevice) && {
        cursor: 'pointer',
      }),
    };
    const warningSx: MuiSxProps = {
      ...sx,
      color: theme.palette.error.main,
      ...(isSelected && {
        backgroundColor: theme.palette.info.main,
        color: theme.palette.error.main,
      }),
    };

    let updatedBy = treeDevice.updatedBy ?? '';

    updatedBy = updatedBy.toString();

    if (updatedBy === '') {
      updatedBy = treeDevice.createdBy.toString();
    }

    // Hide email
    if (updatedBy.includes('@')) {
      updatedBy = '';
    }

    return (
      <TableRow
        ref={componentRef}
        onClick={(event: React.MouseEvent): void => {
          treeContext.canSelect(treeDevice) &&
            selectDevice(treeDevice, treeContext.selectedTreeItems, treeContext.selectTreeItems, deviceList, event);
        }}
      >
        <TableCell width={treeContext.wideMode ? wideWidths[0] : narrowWidths[0]} sx={sx}>
          {treeDevice.deviceId ? `${treeDevice.deviceId} ` : ' '}
          {treeDevice.new && <Chip label='new' color='success' variant='outlined' size='small' />}
        </TableCell>
        {treeContext.wideMode && (
          <TableCell width={treeContext.wideMode ? wideWidths[1] : narrowWidths[0]} sx={sx}>
            {treeDevice.serialNumber}
            {unassigned && treeDevice.new && <Chip label='new' color='success' variant='outlined' size='small' />}
          </TableCell>
        )}
        <TableCell width={treeContext.wideMode ? wideWidths[2] : narrowWidths[1]} sx={sx}>
          {treeDevice.type}
        </TableCell>
        {treeContext.wideMode && (
          <>
            <TableCell width={wideWidths[3]} sx={sx}>
              {instanceOfTreeDeviceFE(treeDevice) ? lastSeen : ''}
            </TableCell>
            {!unassigned && (
              <TableCell width={wideWidths[4]} sx={sx}>
                {instanceOfTreeDeviceFE(treeDevice) ? treeDevice.state : ''}
              </TableCell>
            )}
          </>
        )}
        <TableCell width={treeContext.wideMode ? wideWidths[5] : narrowWidths[2]} sx={unassigned ? sx : warningSx}>
          {unassigned ? updatedBy : instanceOfTreeDeviceFE(treeDevice) && treeDevice.warning && <Icon name='alarms' />}
        </TableCell>
      </TableRow>
    );
  }
}
