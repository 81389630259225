import React, { useState } from 'react';
import Paper from '@mui/material/Paper';
import { COMPONENT_PADDING } from '../../../themes/theme';
import { User, UsersData } from '../../../model/backendDataModels';
import UserDetails from './UserDetails';
import {
  instanceOfTreeCustomerFE,
  instanceOfTreeFleetFE,
  instanceOfTreeFleetGroupFE,
  TreeCustomerFE,
  TreeDeviceFE,
  TreeFleetFE,
  TreeFleetUnassignedFE,
  TreeGatewayFE,
  TreeItemFE,
} from '../../../model/frontendDataModels';
import { treeCustomerParentTo } from '../../../utils/treeCustomerFunctions';
import UserAccessSelector from './UserAccessSelector';
import { Box } from '@mui/material';

function getAllChildren(
  treeItem: Exclude<TreeItemFE, TreeFleetFE | TreeFleetUnassignedFE | TreeDeviceFE | TreeGatewayFE>
): Exclude<TreeItemFE, TreeFleetUnassignedFE | TreeDeviceFE | TreeGatewayFE>[] {
  if (instanceOfTreeCustomerFE(treeItem)) {
    const grandChildren = treeItem.children.flatMap(child => getAllChildren(child));
    const fleetGroups = [...treeItem.fleetgroups, ...treeItem.fleetgroups.flatMap(fg => getAllChildren(fg))];
    const fleets = treeItem.fleets.filter(fleet => instanceOfTreeFleetFE(fleet)) as TreeFleetFE[];
    return [...treeItem.children, ...grandChildren, ...fleetGroups, ...fleets];
  }
  if (instanceOfTreeFleetGroupFE(treeItem)) {
    const grandChildren = treeItem.fleetgroups.flatMap(fg => getAllChildren(fg));
    const fleets = treeItem.fleets.filter(fleet => instanceOfTreeFleetFE(fleet)) as TreeFleetFE[];
    return [...treeItem.fleetgroups, ...grandChildren, ...fleets];
  }
  return [];
}

function getAllParents(
  topCustomer: TreeCustomerFE,
  treeItem: Exclude<TreeItemFE, TreeFleetUnassignedFE | TreeDeviceFE | TreeGatewayFE>
): Exclude<TreeItemFE, TreeFleetFE | TreeFleetUnassignedFE | TreeDeviceFE | TreeGatewayFE>[] {
  const parent = treeCustomerParentTo(topCustomer, treeItem as Exclude<TreeItemFE, TreeFleetUnassignedFE | TreeDeviceFE | TreeGatewayFE>);
  if (parent && parent !== topCustomer) {
    const grandParents = getAllParents(topCustomer, parent);
    return [parent, ...grandParents];
  }
  return parent ? [parent] : [];
}

function addParentsToList(
  topCustomer: TreeCustomerFE,
  treeItem: Exclude<TreeItemFE, TreeFleetUnassignedFE | TreeDeviceFE | TreeGatewayFE>,
  list: Exclude<TreeItemFE, TreeFleetUnassignedFE | TreeDeviceFE | TreeGatewayFE>[]
): void {
  const parents = getAllParents(topCustomer, treeItem);
  parents.forEach(parent => {
    if (!list.includes(parent)) {
      list.push(parent);
    }
  });
}

function addChildrensToList(
  treeItem: Exclude<TreeItemFE, TreeFleetUnassignedFE | TreeDeviceFE | TreeGatewayFE>,
  list: Exclude<TreeItemFE, TreeFleetUnassignedFE | TreeDeviceFE | TreeGatewayFE>[]
): void {
  if (!instanceOfTreeFleetFE(treeItem)) {
    const children = getAllChildren(treeItem);
    children.forEach(child => {
      if (!list.includes(child)) {
        list.push(child);
      }
    });
  }
}

type Props = {
  user: User;
  selectedCustomer: TreeCustomerFE;
  usersData: UsersData;
  close: () => void;
  handleSave: (user: User) => Promise<void>;
  handleDeleteUser: (email: string) => Promise<boolean>;
};

export default function AddOrEditUser({ user, selectedCustomer, usersData, close, handleSave, handleDeleteUser }: Props): JSX.Element {
  const [selectedTreeCustomers, setSelectedTreeCustomers] = useState<TreeCustomerFE[]>([]);

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row',
        flex: 1,
      }}
    >
      <Paper
        elevation={0}
        sx={{
          display: 'flex',
          flexDirection: 'row',
          height: '100%',
          borderRight: '2px solid black',
          overflowX: 'auto',
          flexShrink: 0,
          padding: `${COMPONENT_PADDING}px`,
        }}
      >
        <UserDetails
          user={user}
          selectedCustomer={selectedCustomer}
          selectedTreeCustomers={selectedTreeCustomers}
          setSelectedTreeCustomers={setSelectedTreeCustomers}
          close={close}
          usersData={usersData}
          handleSave={handleSave}
          handleDeleteUser={handleDeleteUser}
        />
      </Paper>
      <UserAccessSelector
        rootTreeCustomer={selectedCustomer}
        canSelect={(treeItem: TreeItemFE): boolean => {
          return instanceOfTreeCustomerFE(treeItem);
        }}
        selectedTreeItems={selectedTreeCustomers}
        selectTreeItems={(items): void => {
          const newSelectedItems: TreeCustomerFE[] = [selectedCustomer];

          if (items.length !== 1 && items.length < selectedTreeCustomers.length) {
            items.forEach(item => {
              newSelectedItems.push(item as TreeCustomerFE);
              addParentsToList(selectedCustomer, item as TreeCustomerFE, newSelectedItems);
            });
          } else if (items.length !== 1 && items.length > selectedTreeCustomers.length) {
            items.forEach(item => {
              if (!newSelectedItems.includes(item as TreeCustomerFE)) {
                newSelectedItems.push(item as TreeCustomerFE);
                if (!selectedTreeCustomers.includes(item as TreeCustomerFE)) {
                  addChildrensToList(item as TreeCustomerFE, newSelectedItems);
                }
                addParentsToList(selectedCustomer, item as TreeCustomerFE, newSelectedItems);
              }
            });
          } else {
            newSelectedItems.push(items[0] as TreeCustomerFE);
            addChildrensToList(items[0] as TreeCustomerFE, newSelectedItems);
            addParentsToList(selectedCustomer, items[0] as TreeCustomerFE, newSelectedItems);
          }

          setSelectedTreeCustomers(newSelectedItems);
        }}
      />
    </Box>
  );
}
