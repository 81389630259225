import React, { useContext } from 'react';
import { IconButton } from '@mui/material';
import SortableList from '../../fleetView/common/SortableList';
import { AppContext } from '../../../App';
import { User, UsersData } from '../../../model/backendDataModels';
import { treeCustomerFindById } from '../../../utils/treeCustomerFunctions';
import Icon from '../../common/Icon';

type Props = {
  usersData: UsersData;
  customerId: string;
  setEditUser: (user: User) => void;
};

export default function ListUsers({ usersData, customerId, setEditUser }: Props): JSX.Element {
  const appContext = useContext(AppContext);

  const userTableHeaders = ['Email', 'Customer', 'Role', 'Edit'];

  function customerIdToName(customerId: string): string {
    if (appContext.rootTreeCustomer) {
      const customer = treeCustomerFindById(appContext.rootTreeCustomer, customerId);
      return customer ? customer.customerName : '-';
    }
    return '-';
  }

  if (!usersData.users) {
    return <></>;
  }

  const userTable = usersData.users
    .filter((user: User) => user.customerId === customerId)
    .map((user: User) => {
      return [
        user.email,
        customerIdToName(user.customerId),
        usersData.roles[user.roleId],
        <>
          <IconButton
            color='info'
            onClick={(): void => {
              setEditUser(user);
            }}
          >
            <Icon name='edit' />
          </IconButton>
        </>,
      ];
    });

  return <SortableList sx={{ maxWidth: 900 }} headers={userTableHeaders} data={userTable} />;
}
