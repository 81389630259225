import React, { useContext } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { COMPONENT_PADDING } from '../../themes/theme';
import { FormControl, MenuItem, Paper, Select, SelectChangeEvent, Stack, Switch, useTheme } from '@mui/material';
import Store from '../../store/Store';
import { timeZonesNames } from '@vvo/tzdb';
import SettingsIcon from '@mui/icons-material/Settings';

function Settings(): JSX.Element {
  const { state, dispatch } = useContext(Store);

  const theme = useTheme();

  const timezone = state === undefined ? '' : state.timezone;
  const fahrenheit = state === undefined ? false : state.temperatureMode === 'Fahrenheit';

  return (
    <FormControl sx={{ display: 'flex', flexDirection: 'column' }}>
      <Stack direction='column' spacing={1} sx={{ justifyContent: 'flex-start', alignItems: 'flex-start' }}>
        <Stack direction='row' sx={{ width: '100%', justifyContent: 'space-between', alignItems: 'center' }}>
          <Typography variant='columnHeader'>Display temperature as Fahrenheit</Typography>
          <Switch
            checked={fahrenheit}
            onChange={(event, checked): void => dispatch({ type: 'SET_TEMPERATURE_MODE', payload: checked ? 'Fahrenheit' : 'Celcius' })}
            color='info'
          />
        </Stack>
        <Stack direction='column' spacing={1} sx={{ justifyContent: 'flex-start', alignItems: 'flex-start' }}>
          <Typography variant='columnHeader'>The timezone</Typography>
          <Select
            value={timezone}
            sx={{
              height: '40px',
              width: '300px',
              backgroundColor: theme.palette.background.default,
              borderRadius: '5px',
              '&:before': {
                borderRadius: '5px',
              },
            }}
            onChange={(event: SelectChangeEvent<string>): void => {
              dispatch({ type: 'SET_TIMEZONE', payload: event.target.value });
            }}
            MenuProps={{ disablePortal: true }}
          >
            {timeZonesNames.map((timezone, index) => (
              <MenuItem key={index} value={timezone} sx={{ height: '40px' }}>
                {timezone}
              </MenuItem>
            ))}
          </Select>
        </Stack>
      </Stack>
    </FormControl>
  );
}

export default function UserSetting(): JSX.Element {
  const theme = useTheme();

  return (
    <Paper elevation={3} sx={{ display: 'inline-block' }}>
      <Stack
        direction='row'
        sx={{
          justifyContent: 'flex-start',
          alignItems: 'center',
          backgroundColor: theme.palette.background.default,
          padding: `${COMPONENT_PADDING - 8}px`,
        }}
      >
        <SettingsIcon fontSize='large' sx={{ color: theme.palette.action.disabled }} />
        <Stack direction='column' sx={{ marginLeft: '1em', justifyContent: 'flex-start', alignItems: 'flex-start' }}>
          <Typography variant='sectionHeader'>Settings</Typography>
          <Typography variant='tableText' sx={{ fontSize: '11px' }}>
            Will be reset on reload
          </Typography>
        </Stack>
      </Stack>
      <Box sx={{ display: 'flex', flexDirection: 'column', padding: `${COMPONENT_PADDING}px`, paddingTop: '16px' }}>
        <Settings />
      </Box>
    </Paper>
  );
}
