import React, { useContext, useState } from 'react';
import Box from '@mui/material/Box';
import TabbedPage, { Page } from '../../../common/TabbedPage';
import EventLogTab from './EventLogTab';
import DeviceHeaderInfo from '../common/DeviceHeaderInfo';
import Typography from '@mui/material/Typography';
import { COMPONENT_PADDING } from '../../../../themes/theme';
import { BmsBasicLogInfo, BasicLogInfo, LogChart, LogChartOptions } from '../../../../model/backendDataModels';
import InstantChartTab from './InstantChartTab';
import HistoryChartTab from './HistoryChartTab';
import HistoryLogTab from './HistoryLogTab';
import { useBasicLogInfo, useHistoryChart, useHistoryChartOptions, useInstantChart } from '../../../../dataHooks/deviceHooks';
import { SWRResponse } from 'swr';
import { TreeDeviceFE } from '../../../../model/frontendDataModels';
import ProtectiveLogTab from './ProtectiveLogTab';
import { useBmsBasicLogInfo } from '../../../../dataHooks/bmsHooks';
import { AppContext } from '../../../../App';
import LoadingIndicator from '../../../common/LoadingIndicator';
import { BackendError } from '../../../../utils/BackendError';
import moment from 'moment-timezone';
import Store from '../../../../store/Store';

function createPages(treeDevice: TreeDeviceFE): Page[] {
  const pages: Page[] = [];

  function getHistoryChartDataHook(from: Date, to: Date): SWRResponse<LogChart, BackendError> {
    return useHistoryChart(treeDevice.productCategory === 'bmu' ? 'bmu' : 'charger', treeDevice.mui, from, to);
  }

  function getHistoryChartOptionsDataHook(): SWRResponse<LogChartOptions, BackendError> {
    return useHistoryChartOptions(treeDevice.productCategory === 'bmu' ? 'bmu' : 'charger');
  }

  function getInstantChartDataHook(from: Date, duration: number): SWRResponse<LogChart, BackendError> {
    return useInstantChart(treeDevice.productCategory === 'bmu' ? 'bmu' : 'charger', treeDevice.mui, from, duration);
  }

  if (treeDevice.productCategory === 'bmu' || treeDevice.productCategory === 'bms' || treeDevice.productCategory === 'charger') {
    pages.push({
      name: 'History log',
      body: <HistoryLogTab mui={treeDevice.mui} serialNumber={treeDevice.serialNumber} productCategory={treeDevice.productCategory} />,
    });
  }

  if (treeDevice.productCategory === 'bmu' || treeDevice.productCategory === 'charger') {
    pages.push({
      name: 'History chart',
      body: <HistoryChartTab dataHook={getHistoryChartDataHook} optionsHook={getHistoryChartOptionsDataHook} />,
    });
  }

  if (treeDevice.productCategory === 'charger' || treeDevice.productCategory === 'bmu' || treeDevice.productCategory === 'bms') {
    pages.push({
      name: 'Event log',
      body: <EventLogTab mui={treeDevice.mui} serialNumber={treeDevice.serialNumber} productCategory={treeDevice.productCategory} />,
    });
  }

  if (treeDevice.productCategory === 'bms') {
    pages.push({
      name: 'Protective log',
      body: <ProtectiveLogTab mui={treeDevice.mui} serialNumber={treeDevice.serialNumber} />,
    });
  }

  if (treeDevice.productCategory === 'bmu' || treeDevice.productCategory === 'charger') {
    pages.push({
      name: 'Instant chart',
      body: <InstantChartTab dataHook={getInstantChartDataHook} productCategory={treeDevice.productCategory} />,
    });
  }

  return pages;
}

type LogFilesTabHeaderProps = {
  treeDevice: TreeDeviceFE;
  tabName: string;
};

function LogFilesTabHeader({ treeDevice, tabName }: LogFilesTabHeaderProps): JSX.Element | null {
  const appContext = useContext(AppContext);
  const { state } = useContext(Store);

  const { data: basicLogsInfo, error } =
    treeDevice.productCategory === 'bms'
      ? useBmsBasicLogInfo(treeDevice.mui)
      : treeDevice.productCategory === 'bmu'
      ? useBasicLogInfo('bmu', treeDevice.mui)
      : useBasicLogInfo('charger', treeDevice.mui);
  let content = null;

  if (error) {
    content = null;
  } else if (basicLogsInfo) {
    const latestHistoryLogUpdate = moment(basicLogsInfo.latestHistoryLogUpdate, moment.ISO_8601)
      .tz(state.timezone)
      .format('YYYY-MM-DD HH:mm:ss');
    const latestEventLogUpdate = moment(basicLogsInfo.latestEventLogUpdate, moment.ISO_8601)
      .tz(state.timezone)
      .format('YYYY-MM-DD HH:mm:ss');

    if (treeDevice.productCategory === 'bms') {
      const bmsBasicLogInfo = basicLogsInfo as BmsBasicLogInfo;

      const latestProtectiveLogUpdate = moment(bmsBasicLogInfo.latestProtectiveLogUpdate, moment.ISO_8601)
        .tz(state.timezone)
        .format('YYYY-MM-DD HH:mm:ss');

      content = (
        <>
          <DeviceHeaderInfo label='Serial number' text={basicLogsInfo.serialNumber} />

          {tabName.startsWith('History') && <DeviceHeaderInfo label='Latest history log update' text={latestHistoryLogUpdate} />}
          {tabName.startsWith('Event') && <DeviceHeaderInfo label='Latest event log update' text={latestEventLogUpdate} />}
          {tabName.startsWith('Protective') && <DeviceHeaderInfo label='Latest protective log update' text={latestProtectiveLogUpdate} />}
        </>
      );
    } else {
      const basicLogInfo = basicLogsInfo as BasicLogInfo;

      const latestInstantLogUpdate = moment(basicLogInfo.latestInstantLogUpdate, moment.ISO_8601)
        .tz(state.timezone)
        .format('YYYY-MM-DD HH:mm:ss');
      content = (
        <>
          <DeviceHeaderInfo label='Serial number' text={basicLogsInfo.serialNumber} />

          {tabName.startsWith('History') && <DeviceHeaderInfo label='Latest history log update' text={latestHistoryLogUpdate} />}
          {tabName.startsWith('Event') && <DeviceHeaderInfo label='Latest event log update' text={latestEventLogUpdate} />}
          {tabName.startsWith('Instant') && <DeviceHeaderInfo label='Latest instant log update' text={latestInstantLogUpdate} />}
        </>
      );
    }
  } else {
    content = <LoadingIndicator />;
  }

  if (error) {
    appContext.addBackendError(error);
  }

  return <Box sx={{ display: 'flex', flexDirection: 'row' }}>{content}</Box>;
}

type LogFilesTabProps = {
  treeDevice: TreeDeviceFE;
  tabMode: boolean;
};

export default function LogFilesTab({ treeDevice, tabMode }: LogFilesTabProps): JSX.Element {
  const [selectedTabName, setSelectedTabName] = useState<string | null>();

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', height: tabMode ? '100%' : '100vh', borderTop: '2px solid black' }}>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'start',
          padding: `${COMPONENT_PADDING}px`,
          paddingBottom: 0,
        }}
      >
        <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'end' }}>
          <Typography variant='bigHeader' component='div' sx={{ marginBottom: '15px' }}>
            Logfiles
          </Typography>
        </Box>
        {selectedTabName && <LogFilesTabHeader treeDevice={treeDevice} tabName={selectedTabName} />}
      </Box>
      <TabbedPage pages={createPages(treeDevice)} disableScroll={true} onChangeActiveTab={setSelectedTabName} />
    </Box>
  );
}
