import React, { useContext, useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import TabbedPage, { Page } from '../../common/TabbedPage';
import BmsHistogramTab from './bmsHistogramTab/BmsHistogramTab';
import ConfigurationTab from './ConfigurationTab';
import LogFilesTab from './logFilesTab/LogFilesTab';
import SelectedDeviceHeader from './selectedDeviceHeader/SelectedDeviceHeader';
import InactiveFleetDeviceView from './InactiveFleetDeviceView';
import ChargerOverviewTab from './chargerOverviewTab/ChargerOverviewTab';
import BatteryOverviewTab from './batteryOverviewTab/BatteryOverviewTab';
import { treeCustomerFindTreeFleetByDevice, treeCustomerUpdateTreeFleet } from '../../../utils/treeCustomerFunctions';
import { activateFleet, deactivateFleet } from '../../../services/fleetManipulation';
import { LoginContext } from '../../../Login';
import { AppContext } from '../../../App';
import { BackendError } from '../../../utils/BackendError';
import EmptyFleetDeviceView from './EmptyFleetDeviceView';
import { useChargerOverviewStatus } from '../../../dataHooks/chargerHooks';
import { useBmuOverviewStatus } from '../../../dataHooks/bmuHooks';
import { useBluOverviewStatus } from '../../../dataHooks/bluHooks';
import { BmuOverviewStatus, BluOverviewStatus, ChargerOverviewStatus } from '../../../model/backendDataModels';
import { COMPONENT_PADDING, WIDTH_OF_INFO_COMPONENT } from '../../../themes/theme';
import { TreeFleetFE, TreeDeviceFE } from '../../../model/frontendDataModels';
import { useTheme } from '@mui/material';

function createPages(selectedDevice: TreeDeviceFE): Page[] {
  const pages = [];

  if (selectedDevice.productCategory === 'charger') {
    pages.push({
      name: 'Overview',
      body: <ChargerOverviewTab selectedDevice={selectedDevice} />,
    });
  }
  if (selectedDevice.productCategory === 'bms' || selectedDevice.productCategory === 'bmu') {
    pages.push({
      name: 'Overview',
      body: <BatteryOverviewTab selectedDevice={selectedDevice} />,
    });
  }
  if (selectedDevice.productCategory === 'bms') {
    pages.push({
      name: 'Histogram',
      body: <BmsHistogramTab selectedDevice={selectedDevice} />,
    });
  }
  pages.push({
    name: 'Configuration',
    body: <ConfigurationTab selectedDevice={selectedDevice} />,
  });
  pages.push({
    name: 'Logfiles',
    body: <LogFilesTab treeDevice={selectedDevice} tabMode={true} />,
  });
  return pages;
}

function getStatus(selectedDevice: TreeDeviceFE): {
  data: BmuOverviewStatus | BluOverviewStatus | ChargerOverviewStatus | undefined;
  error: BackendError | undefined;
  isLoading: boolean;
} {
  if (selectedDevice.productCategory === 'charger') {
    const { data, error, isLoading } = useChargerOverviewStatus(selectedDevice.mui);
    return { data, error, isLoading };
  }
  if (selectedDevice.productCategory === 'bmu') {
    if (selectedDevice.type.toLowerCase() === 'blu') {
      const { data, error, isLoading } = useBluOverviewStatus(selectedDevice.mui);
      return { data, error, isLoading };
    } else {
      const { data, error, isLoading } = useBmuOverviewStatus(selectedDevice.mui);
      return { data, error, isLoading };
    }
  }

  return { data: undefined, error: undefined, isLoading: false };
}

type EmptyDeviceContextValues = {
  isInitializedDevice: boolean;
  isActiveFleet: boolean;
  setIsInitializedDevice: (initialized: boolean) => void;
};

export const EmptyDeviceContext = React.createContext<EmptyDeviceContextValues>({
  isInitializedDevice: true,
  isActiveFleet: true,
  // eslint-disable-next-line @typescript-eslint/no-empty-function, @typescript-eslint/no-unused-vars
  setIsInitializedDevice: (initialized: boolean) => {},
});

type SelectedDeviceViewProps = {
  selectedDevice: TreeDeviceFE;
};

function SelectedDeviceView({ selectedDevice }: SelectedDeviceViewProps): JSX.Element | null {
  const defaultStyle = {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    borderRight: '2px solid black',
    width: WIDTH_OF_INFO_COMPONENT * 2 + COMPONENT_PADDING * 2,
  };
  const fullscreenStyle = {
    ...defaultStyle,
    width: '100%',
  };

  const loginContext = useContext(LoginContext);
  const appContext = useContext(AppContext);

  const theme = useTheme();

  const [isInitializedDevice, setIsInitializedDevice] = useState<boolean>(true);
  const [fullscreen, setFullscreen] = useState<boolean>(false);
  const treeFleet = appContext.rootTreeCustomer ? treeCustomerFindTreeFleetByDevice(appContext.rootTreeCustomer, selectedDevice) : null;
  const { data, error, isLoading } = getStatus(selectedDevice);

  if (!treeFleet) {
    return null;
  }

  const isActiveFleet = treeFleet.activated;

  if (error) {
    appContext.addBackendError(error);
  }

  useEffect(() => {
    if (isLoading) {
      return;
    }

    if (data === undefined) {
      setIsInitializedDevice(false);
    } else {
      setIsInitializedDevice(data.initialized);
    }
  }, [data]);

  async function update(active: boolean): Promise<void> {
    if (!loginContext.accessToken) {
      appContext.addBackendError(new BackendError(0, 'No login token', ''));
      return;
    }
    if (treeFleet && appContext.rootTreeCustomer) {
      if (active) {
        const result = await activateFleet(treeFleet.fleetId, loginContext.accessToken, appContext.addBackendError);
        if (result) {
          const newTreeFleet: TreeFleetFE = {
            ...treeFleet,
            activated: true,
          };
          appContext.updateRootTreeCustomer(treeCustomerUpdateTreeFleet(appContext.rootTreeCustomer, newTreeFleet));
        }
      } else {
        const result = await deactivateFleet(treeFleet.fleetId, loginContext.accessToken, appContext.addBackendError);
        if (result) {
          const newTreeFleet: TreeFleetFE = {
            ...treeFleet,
            activated: false,
          };
          appContext.updateRootTreeCustomer(treeCustomerUpdateTreeFleet(appContext.rootTreeCustomer, newTreeFleet));
        }
      }
    }
  }

  if (!appContext.rootTreeCustomer) {
    return <></>;
  }

  return (
    <EmptyDeviceContext.Provider value={{ isInitializedDevice, isActiveFleet, setIsInitializedDevice }}>
      <Box
        sx={fullscreen ? fullscreenStyle : defaultStyle}
        style={{
          transition: theme.transitions.create('all', {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.standard,
          }),
        }}
      >
        <SelectedDeviceHeader selectedDevice={selectedDevice} onFullscreenChanged={setFullscreen} fullscreen={fullscreen} />
        {!isInitializedDevice && isActiveFleet && !isLoading && <EmptyFleetDeviceView />}
        {isActiveFleet && (
          <TabbedPage initialized={isInitializedDevice} tabAlignment='right' pages={createPages(selectedDevice)} disableScroll={true} />
        )}

        {!isActiveFleet && <InactiveFleetDeviceView treeFleet={treeFleet} update={update} />}
      </Box>
    </EmptyDeviceContext.Provider>
  );
}

export default React.memo(SelectedDeviceView);
