import { LanguageType, TemperatureModeType } from '../model/backendDataModels';

export type CommonLogSettings = {
  startDate: Date;
  endDate: Date;
};

export type HistoryChartSettings = {
  startDate: Date;
  endDate: Date;
  leftAxisOptions: string[];
  rightAxisOptions: string[];
};

export type InstantChartSettings = {
  startDate: Date;
  duration: number;
  leftAxisOptions: string[];
  rightAxisOptions: string[];
};

export type LogFilesSettings = {
  historyLogSettings?: CommonLogSettings;
  eventLogSettings?: CommonLogSettings;
  protectiveLogSettings?: CommonLogSettings;
  historyChartSettings?: HistoryChartSettings;
  instantChartSettings?: InstantChartSettings;
};

export type State = {
  language: LanguageType;
  temperatureMode: TemperatureModeType;
  timezone: string;
  logFilesSettings: LogFilesSettings;
};

type SetLanguage = {
  type: 'SET_LANGUAGE';
  payload: LanguageType;
};

type SetTemperatureMode = {
  type: 'SET_TEMPERATURE_MODE';
  payload: TemperatureModeType;
};

type SetTimezone = {
  type: 'SET_TIMEZONE';
  payload: string;
};

type SetHistoryLogSettings = {
  type: 'SET_HISTORY_LOG_SETTINGS';
  payload?: CommonLogSettings;
};

type SetEventLogSettings = {
  type: 'SET_EVENT_LOG_SETTINGS';
  payload?: CommonLogSettings;
};

type SetProtectiveLogSettings = {
  type: 'SET_PROTECTIVE_LOG_SETTINGS';
  payload?: CommonLogSettings;
};

type SetHistoryChartSettings = {
  type: 'SET_HISTORY_CHART_SETTINGS';
  payload?: HistoryChartSettings;
};

type SetInstantChartSettings = {
  type: 'SET_INSTANT_CHART_SETTINGS';
  payload?: InstantChartSettings;
};

export type Actions =
  | SetTemperatureMode
  | SetTimezone
  | SetLanguage
  | SetHistoryLogSettings
  | SetEventLogSettings
  | SetProtectiveLogSettings
  | SetHistoryChartSettings
  | SetInstantChartSettings;

function isFahrenheit(): boolean {
  const fahrenheitCountries = ['US', 'BS', 'BZ', 'KY', 'PW'];
  const locale = Intl.DateTimeFormat().resolvedOptions().locale.toLowerCase();

  for (const country of fahrenheitCountries) {
    if (locale.includes(country.toLowerCase())) {
      return true;
    }
  }

  return false;
}

export const initialState: State = {
  language: 'English',
  temperatureMode: isFahrenheit() ? 'Fahrenheit' : 'Celcius',
  timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
  logFilesSettings: {},
};

export function Reducer(state: State, action: Actions): State {
  switch (action.type) {
    case 'SET_LANGUAGE':
      return {
        ...state,
        language: action.payload,
      };
    case 'SET_TEMPERATURE_MODE':
      return {
        ...state,
        temperatureMode: action.payload,
      };
    case 'SET_TIMEZONE':
      return {
        ...state,
        timezone: action.payload,
      };
    case 'SET_HISTORY_LOG_SETTINGS':
      return {
        ...state,
        logFilesSettings: {
          ...state.logFilesSettings,
          historyLogSettings: action.payload,
        },
      };
    case 'SET_EVENT_LOG_SETTINGS':
      return {
        ...state,
        logFilesSettings: {
          ...state.logFilesSettings,
          eventLogSettings: action.payload,
        },
      };
    case 'SET_PROTECTIVE_LOG_SETTINGS':
      return {
        ...state,
        logFilesSettings: {
          ...state.logFilesSettings,
          protectiveLogSettings: action.payload,
        },
      };
    case 'SET_HISTORY_CHART_SETTINGS':
      return {
        ...state,
        logFilesSettings: {
          ...state.logFilesSettings,
          historyChartSettings: action.payload,
        },
      };
    case 'SET_INSTANT_CHART_SETTINGS':
      return {
        ...state,
        logFilesSettings: {
          ...state.logFilesSettings,
          instantChartSettings: action.payload,
        },
      };
    default:
      return {
        ...state,
      };
  }
}
