export type DataObject<Type> = {
  status: 'success' | 'fail';
  data?: Type;
  message?: string;
};

export type CommonOverviewStatus = {
  productType: string;
  voltage: string;
  capacity: string;
  temperature: number;
  fleet: string;
  timestamp: string;
};

export function instanceOfCommonOverviewStatus(object: Record<string, unknown>): object is CommonOverviewStatus {
  return (
    'productType' in object &&
    'voltage' in object &&
    'capacity' in object &&
    'temperature' in object &&
    'fleet' in object &&
    'timestamp' in object
  );
}

export type BmuOverviewStatus = CommonOverviewStatus & {
  state: string;
  soc: string;
  soh: string;
  chargingCurve: string;
  activeAlarms: string;
  deviceId: string;
  warningText: string;
  initialized: boolean;
};

export function instanceOfBmuOverviewStatus(object: Record<string, unknown>): object is BmuOverviewStatus {
  return (
    instanceOfCommonOverviewStatus(object) &&
    'state' in object &&
    'soc' in object &&
    'soh' in object &&
    'chargingCurve' in object &&
    'activeAlarms' in object &&
    'deviceId' in object &&
    'warningText' in object &&
    'initialized' in object
  );
}

export type BluOverviewStatus = CommonOverviewStatus & {
  state: string;
  soc: string;
  soh: string;
  activeAlarms: string;
  deviceId: string;
  warningText: string;
  initialized: boolean;
};

export function instanceOfBluOverviewStatus(object: Record<string, unknown>): object is BluOverviewStatus {
  return (
    instanceOfCommonOverviewStatus(object) &&
    'state' in object &&
    'soc' in object &&
    'soh' in object &&
    'activeAlarms' in object &&
    'deviceId' in object &&
    'warningText' in object &&
    'initialized' in object
  );
}

export type BmsOverviewStatus = CommonOverviewStatus & {
  totalsCycle: number;
  soh: string;
  dischargedAh: string;
  regenAh: string;
  chargedAh: string;
  deepDischarges: number;
  serialNumber: number;
};

export function instanceOfBmsOverviewStatus(object: Record<string, unknown>): object is BmsOverviewStatus {
  return (
    instanceOfCommonOverviewStatus(object) &&
    'totalsCycle' in object &&
    'soh' in object &&
    'dischargedAh' in object &&
    'regenAh' in object &&
    'chargedAh' in object &&
    'deepDischarges' in object &&
    'serialNumber' in object
  );
}

export type ChargerOverviewStatus = CommonOverviewStatus & {
  state: string;
  chargingCurve: string;
  activeAlarms: string;
  deviceId: string;
  warningText: string;
  initialized: boolean;
};

export function instanceOfChargerOverviewStatus(object: Record<string, unknown>): object is ChargerOverviewStatus {
  return (
    instanceOfCommonOverviewStatus(object) &&
    'state' in object &&
    !('soc' in object) &&
    !('soh' in object) &&
    'chargingCurve' in object &&
    'activeAlarms' in object &&
    'deviceId' in object &&
    'warningText' in object &&
    'initialized' in object
  );
}

export type Alarms = {
  alarmNames: string[];
  quantity: number[];
};

export type LanguageType = 'English';
export type TemperatureModeType = 'Celcius' | 'Fahrenheit';
export const languageList: string[] = ['English'];

export type SystemSettingsData = {
  temperatureMode: TemperatureModeType;
  language: LanguageType;
  timezone: string;
};

export type TreeDevice = {
  mui: string;
  deviceId?: string;
  deviceTag?: string;
  serialNumber: string;
  type: string;
  productCategory: 'bmu' | 'bms' | 'charger';
  lastSeen: string;
  state: string;
  warning: boolean;
  new: boolean;
  createdBy: string;
  updatedBy: string;
};

export function instanceOfTreeDevice(object: Record<string, unknown>): object is TreeDevice {
  return (
    'mui' in object &&
    'productCategory' in object &&
    (object.productCategory === 'bmu' || object.productCategory === 'bms' || object.productCategory === 'charger') &&
    'serialNumber' in object &&
    'lastSeen' in object &&
    'type' in object &&
    'state' in object &&
    'warning' in object &&
    'new' in object
  );
}

export type TreeDeviceUnassigned = {
  mui: string;
  productCategory: 'bmu' | 'bms' | 'charger';
  deviceId?: string;
  deviceTag?: string;
  serialNumber?: string;
  lastSeen: string;
  type: string;
  new: boolean;
  createdBy: string;
  updatedBy: string;
};

export function instanceOfTreeDeviceUnassigned(object: Record<string, unknown>): object is TreeDeviceUnassigned {
  return (
    'mui' in object &&
    'productCategory' in object &&
    (object.productCategory === 'bmu' || object.productCategory === 'bms' || object.productCategory === 'charger') &&
    'lastSeen' in object &&
    'type' in object &&
    'new' in object
  );
}

export type TreeGateway = {
  mui: string;
  productCategory: 'gateway';
  deviceId?: string;
  deviceTag?: string;
  serialNumber: string;
  lastSeen: string;
  noOfNodes: number;
  state: 'Offline' | 'Online';
  type: 'Cirrus gateway';
  new: boolean;
  createdBy: string;
  updatedBy: string;
};

export function instanceOfTreeGateway(object: Record<string, unknown>): object is TreeGateway {
  return (
    'mui' in object &&
    'productCategory' in object &&
    object.productCategory === 'gateway' &&
    'serialNumber' in object &&
    'lastSeen' in object &&
    'noOfNodes' in object &&
    'state' in object &&
    'type' in object &&
    object.type === 'Cirrus gateway' in object &&
    'new' in object
  );
}

export type TreeGatewayUnassigned = {
  mui: string;
  productCategory: 'gateway';
  deviceId?: string;
  deviceTag?: string;
  serialNumber: string;
  lastSeen: string;
  noOfNodes: number;
  state: 'Offline' | 'Online';
  type: 'Cirrus gateway';
  new: boolean;
  createdBy: string;
  updatedBy: string;
};

export function instanceOfTreeGatewayUnassigned(object: Record<string, unknown>): object is TreeGatewayUnassigned {
  return (
    'mui' in object &&
    'productCategory' in object &&
    object.productCategory === 'gateway' &&
    'serialNumber' in object &&
    'lastSeen' in object &&
    'noOfNodes' in object &&
    'state' in object &&
    'type' in object &&
    object.type === 'Cirrus gateway' in object &&
    'new' in object
  );
}

export type TreeFleet = {
  fleetId: string;
  fleetName: string;
  description: string;
  activated: boolean;
  lat: number | null;
  lng: number | null;
  address: string;
  timezone: string;
  demo?: true;
  batteries?: TreeDevice[];
  chargers?: TreeDevice[];
  gateways?: TreeGateway[];
};

export function instanceOfTreeFleet(object: Record<string, unknown>): object is TreeFleet {
  return (
    'fleetId' in object &&
    'fleetName' in object &&
    object.fleetName !== '[unassigned]' &&
    'description' in object &&
    'activated' in object &&
    'lat' in object &&
    'lng' in object &&
    'address' in object &&
    'timezone' in object
  );
}

export type TreeFleetUnassigned = {
  fleetName: '[unassigned]';
  batteries?: TreeDeviceUnassigned[];
  chargers?: TreeDeviceUnassigned[];
  gateways?: TreeGatewayUnassigned[];
};

export function instanceOfTreeFleetUnassigned(object: Record<string, unknown>): object is TreeFleetUnassigned {
  return 'fleetName' in object && object.fleetName === '[unassigned]';
}

export type TreeFleetUnassignedWrapper = {
  fakeId: string;
} & TreeFleetUnassigned;

export function instanceOfTreeFleetUnassignedWrapper(object: Record<string, unknown>): object is TreeFleetUnassignedWrapper {
  return instanceOfTreeFleetUnassigned(object) && 'fakeId' in object;
}

export type TreeFleetGroup = {
  fleetgroupId: string;
  fleetgroupName: string;
  description: string;
  fleets: TreeFleet[];
  fleetgroups: TreeFleetGroup[];
};

export function instanceOfTreeFleetGroup(object: Record<string, unknown>): object is TreeFleetGroup {
  return 'fleetgroupId' in object && 'fleetgroupName' in object && 'description' in object && 'fleets' in object && 'fleetgroups' in object;
}

export type TreeCustomer = {
  customerId: string;
  customerName: string;
  description: string;
  timezone: string;
  fleets: (TreeFleet | TreeFleetUnassigned)[];
  fleetgroups: TreeFleetGroup[];
  children: TreeCustomer[];
};

export function instanceOfTreeCustomer(object: Record<string, unknown>): object is TreeCustomer {
  return (
    'customerId' in object &&
    'customerName' in object &&
    'description' in object &&
    'fleets' in object &&
    'fleetgroups' in object &&
    'children' in object
  );
}

export type TreeItem =
  | TreeCustomer
  | TreeFleetGroup
  | TreeFleet
  | TreeFleetUnassignedWrapper
  | TreeDevice
  | TreeDeviceUnassigned
  | TreeGateway
  | TreeGatewayUnassigned;

export type LineChartData = {
  yLabel: string; // % or other
  yLabelDisplay: boolean;
  xLabel: string;
  xLabelDisplay: boolean;
  xmin: number;
  xmax: number;
  ymin: number;
  ymax: number;
  values: (number | null)[];
};

export type CalendarStateData = (number | null)[];

export type ListTabs = {
  alarms: boolean;
  soc: boolean;
  equalizing: boolean;
  charging: boolean;
  equalizingCycles: number | null;
  chargingPerWeek: number[] | null;
};

export type FieldsWithSingleValues = {
  fields: Field[];
  values: (string | number)[];
};

export type ConfigData = {
  basic: FieldsWithSingleValues;
  advanced: FieldsWithSingleValues;
  other: FieldsWithSingleValues;
};

type Histogram = {
  xvalues: number[];
  y1values: number[];
  y2values: number[];
};

export type BmsHistograms = {
  temperature: Histogram;
  voltage: Histogram;
  current: Histogram;
};

export type BmsBasicLogInfo = {
  serialNumber: string;
  latestHistoryLogUpdate: string;
  latestConfigLogUpdate: string;
  latestEventLogUpdate: string;
  latestProtectiveLogUpdate: string;
  latestHistogramUpdate: string;
};

export type BasicLogInfo = {
  serialNumber: string;
  latestHistoryLogUpdate: string;
  latestConfigLogUpdate: string;
  latestEventLogUpdate: string;
  latestInstantLogUpdate: string;
  latestStatusUpdate: string;
};

export type Field = {
  name: string;
  type: string;
};

export type FieldsAndValues = {
  fields: Field[];
  values: (string | number)[][];
};

export type FieldsAndNumberValues = {
  fields: Field[];
  values: number[][];
};

export type LogData = {
  basic: FieldsAndValues;
  advanced: FieldsAndValues;
  other: FieldsAndValues;
};

export function instanceOfLogData(object: Record<string, unknown>): object is LogData {
  return 'basic' in object && 'advanced' in object && 'other' in object;
}

export type ServiceLog = {
  createdBy: string;
  createdAt: string;
  updatedBy: string;
  updatedAt: string;
  mui: string;
  eventId: string;
  eventType: string;
  description: string;
  status: string;
};

export type ServiceLogContent = {
  eventType: string;
  description: string;
  status: string;
};

export type AggregatedAlarms = {
  alarmNames: string[];
  quantity: number[];
};

export type LogChart = {
  x: string[];
  y_names: string[];
  type: string[];
  y: number[][];
};

export type LogChartOptions = {
  yaxis1: string[];
  yaxis2: string[][];
};

export const ROLE_ID_INVOICE_ADMIN = 11;
export const ROLE_ID_SYSTEM_ADMIN = 10;

export type PostUser = {
  customerId: string;
  roleId: number;
  subcustomerIds: string[];
};

export type User = PostUser & {
  email: string;
};

export type UsersData = {
  roles: Record<string, string>;
  users: User[];
};

export type BundleSubscription = {
  startDate?: string; // format 'YYYY-MM-DD'
  endDate?: string; // format 'YYYY-MM-DD'
};

export type CustomerInvoiceInfo = {
  customerId: string;
  customerName: string;
  activeDevices: number;
};

export type InvoiceDataInvAdmin = {
  customerId: string;
  customerName: string;
  totalDevices: number;
  customers: CustomerInvoiceInfo[];
  bundles: BundleSubscription[];
  activeDemoDevices?: number;
  totalDemoDevices?: number;
};

export type InvoiceDataSysAdmin = InvoiceDataInvAdmin[];

export type MaintenanceMessage = {
  title: string;
  message: string;
};

export type MaintenanceInfo = {
  message: MaintenanceMessage | null;
};
