import { TemperatureModeType } from '../model/backendDataModels';

export function convertTemperature(celsius: number, temperatureMode: TemperatureModeType, precision: number): number {
  const fahrenheit = temperatureMode === 'Fahrenheit';

  if (fahrenheit) {
    const convertedTemperature = parseFloat((celsius * 1.8 + 32).toFixed(precision));

    return convertedTemperature;
  } else {
    const convertedTemperature = parseFloat((celsius * 1).toFixed(precision));

    return convertedTemperature;
  }
}

export function toTemperatureString(celsius: number, temperatureMode: TemperatureModeType, precision: number): string {
  const fahrenheit = temperatureMode === 'Fahrenheit';
  const convertedTemperature = convertTemperature(celsius, temperatureMode, precision);

  return `${convertedTemperature} °${fahrenheit ? 'F' : 'C'}`;
}

export function getTemperatureUnit(temperatureMode: TemperatureModeType): string {
  const fahrenheit = temperatureMode === 'Fahrenheit';

  return `°${fahrenheit ? 'F' : 'C'}`;
}
