import React, { useState, useContext } from 'react';
import Pagination from '@mui/material/Pagination';
import Box from '@mui/material/Box';
import { Field, FieldsAndValues, TemperatureModeType } from '../../../../../model/backendDataModels';
import ComponentBase from '../../componentBase/ComponentBase';
import SortableList from '../../SortableList';
import { SWRResponse } from 'swr';
import { BackendError } from '../../../../../utils/BackendError';
import LoadingIndicator from '../../../../common/LoadingIndicator';
import { AppContext } from '../../../../../App';
import { sortData } from '../../../../../utils/dataSortUtils';
import Store from '../../../../../store/Store';
import moment from 'moment-timezone';
import { convertTemperature, getTemperatureUnit } from '../../../../../utils/temperatureUtils';

function sortObjectData(fieldsAndValues: FieldsAndValues, sortCol: number | undefined, sortAsc: boolean | undefined): FieldsAndValues {
  return {
    fields: fieldsAndValues.fields,
    values: sortData(fieldsAndValues.values, sortCol, sortAsc),
  };
}

function getFieldName(field: Field, temperatureMode: TemperatureModeType): string {
  if (field.type === 'temperature') {
    return `${field.name} (${getTemperatureUnit(temperatureMode)})`;
  } else {
    return field.name;
  }
}

function mergeObjectData(fieldsAndValues: FieldsAndValues, timezone: string, temperatureMode: TemperatureModeType): FieldsAndValues {
  const mergedValues: string[][] = [];

  fieldsAndValues.values.forEach(value => {
    const values: string[] = [];

    fieldsAndValues.fields.forEach((field, index) => {
      if (field.type === 'datetime') {
        const date = moment(value[index], moment.ISO_8601).tz(timezone);
        const dateString = date.format('YYYY-MM-DD HH:mm:ss');

        values.push(dateString);
      } else if (field.type === 'boolean') {
        values.push(value[index] ? 'Active' : 'Inactive');
      } else if (field.type === 'number') {
        values.push(value[index].toString());
      } else if (field.type === 'temperature') {
        values.push(convertTemperature(value[index] as number, temperatureMode, 2).toString());
      } else {
        values.push(value[index] as string);
      }
    });

    mergedValues.push(values);
  });

  return {
    fields: fieldsAndValues.fields,
    values: mergedValues,
  };
}

const MAX_ITEMS_PER_PAGE = 10;

type EventLogProps = {
  dataHook: (startDate: Date, endDate: Date) => SWRResponse<FieldsAndValues, BackendError>;
  title: string;
  startDate: Date;
  endDate: Date;
  help?: React.ReactNode;
};

export default function EventLog({ dataHook, title, startDate, endDate, help }: EventLogProps): JSX.Element {
  const appContext = useContext(AppContext);
  const { state } = useContext(Store);

  const [sortCol, setSortCol] = useState<number | undefined>();
  const [sortAsc, setSortAsc] = useState<boolean | undefined>();
  const [page, setPage] = useState(1);
  const startInteval = moment(startDate).startOf('day');
  const endInterval = moment(endDate).endOf('day');

  const { data: fieldsAndValues, error } = dataHook(startInteval.toDate(), endInterval.toDate());

  if (error) {
    appContext.addBackendError(error);
  }

  const data = fieldsAndValues
    ? mergeObjectData(sortObjectData(fieldsAndValues, sortCol, sortAsc), state.timezone, state.temperatureMode)
    : undefined;
  const totalNumberOfPages = data ? Math.ceil(data.values.length / MAX_ITEMS_PER_PAGE) : 0;

  return (
    <ComponentBase title={title} help={help}>
      {data && data.values.length > 0 && !error && (
        <>
          <SortableList
            data={data.values.slice((page - 1) * MAX_ITEMS_PER_PAGE, Math.min(data.values.length, page * MAX_ITEMS_PER_PAGE))}
            headers={data.fields.map(field => getFieldName(field, state.temperatureMode))}
            widths={[0.3, 0.29, 0.41]}
            changeDataParameters={(sortCol: number, sortAsc: boolean): void => {
              setSortCol(sortCol);
              setSortAsc(sortAsc);
            }}
          />

          {page > 0 && (
            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
              {totalNumberOfPages > 1 && (
                <Pagination
                  count={totalNumberOfPages}
                  page={page}
                  onChange={(event, page): void => {
                    setPage(page);
                  }}
                />
              )}
            </Box>
          )}
        </>
      )}
      {!data && !error && <LoadingIndicator />}
    </ComponentBase>
  );
}
