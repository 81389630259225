import React, { useState, useEffect, useContext } from 'react';
import Box from '@mui/material/Box';
import LoadingIndicator from '../../../common/LoadingIndicator';
import LabelledInput from './common/LabelledInput';
import { COMPONENT_PADDING } from '../../../../themes/theme';
import { subMonths, subDays, addDays } from 'date-fns';
import AxisOptionsSelect from './common/AxisOptionsSelect';
import InputRow from './common/InputRow';
import { SWRResponse } from 'swr';
import { LogChart, LogChartOptions, TemperatureModeType } from '../../../../model/backendDataModels';
import Store from '../../../../store/Store';
import MultiAxisChart, { AxisType, AxisTypes } from './common/multiAxisChart/MultiAxisChart';
import InputSelect from './InputSelect';
import { Typography } from '@mui/material';
import ValidatedDatePicker from './common/ValidatedDatePicker';
import { AppContext } from '../../../../App';
import { BackendError } from '../../../../utils/BackendError';
import moment from 'moment';
import { utcToZonedTime } from 'date-fns-tz';
import { convertTemperature } from '../../../../utils/temperatureUtils';

type Props = {
  dataHook: (startDate: Date, endDate: Date) => SWRResponse<LogChart, BackendError>;
  optionsHook: () => SWRResponse<LogChartOptions, BackendError>;
};

function getConvertedData(data: number[][], types: string[], temperatureMode: TemperatureModeType): number[][] {
  const convertedData: number[][] = [];

  for (let index = 0; index < data.length; ++index) {
    if (types[index] === 'temperature') {
      convertedData.push(
        data[index].map(celcius => {
          return convertTemperature(celcius, temperatureMode, 1);
        })
      );
    } else {
      convertedData.push(data[index]);
    }
  }

  return convertedData;
}

export default function HistoryChartTab({ dataHook, optionsHook }: Props): JSX.Element {
  const appContext = useContext(AppContext);
  const { state, dispatch } = useContext(Store);

  const now = utcToZonedTime(new Date(), state.timezone);

  const [axisType, setAxisType] = useState<AxisType>('Category');
  const [startDate, setStartDate] = useState<Date>(
    state.logFilesSettings.historyChartSettings ? state.logFilesSettings.historyChartSettings.startDate : subMonths(now, 1)
  );
  const [endDate, setEndDate] = useState<Date>(
    state.logFilesSettings.historyChartSettings ? state.logFilesSettings.historyChartSettings.endDate : now
  );
  const [leftAxisOptions, setLeftAxisOptions] = useState<string[]>(
    state.logFilesSettings.historyChartSettings ? state.logFilesSettings.historyChartSettings.leftAxisOptions : []
  );
  const [rightAxisOptions, setRightAxisOptions] = useState<string[]>(
    state.logFilesSettings.historyChartSettings ? state.logFilesSettings.historyChartSettings.rightAxisOptions : []
  );

  const { data, error } = dataHook(startDate, endDate);

  if (error) {
    appContext.addBackendError(error);
  }

  const { data: options, error: errorOptions } = optionsHook();

  if (errorOptions) {
    appContext.addBackendError(errorOptions);
  }

  useEffect(() => {
    if (leftAxisOptions.length === 0) {
      setRightAxisOptions([]);
    }
  }, [leftAxisOptions]);

  useEffect(() => {
    dispatch({ type: 'SET_HISTORY_CHART_SETTINGS', payload: { startDate, endDate, leftAxisOptions, rightAxisOptions } });
  }, [startDate, endDate, leftAxisOptions, rightAxisOptions]);

  useEffect(() => {
    setLeftAxisOptions([]);
    setRightAxisOptions([]);
  }, [options]);

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', borderTop: '2px solid black', padding: `${COMPONENT_PADDING}px`, height: '100%' }}>
      <InputRow>
        <LabelledInput
          label='Start date'
          input={
            <ValidatedDatePicker
              value={startDate}
              updateValue={(date: Date): void => {
                setStartDate(date);
              }}
              maxDate={subDays(endDate, 1)}
            />
          }
        />
        <LabelledInput
          label='End date'
          input={
            <ValidatedDatePicker
              value={endDate}
              updateValue={(date): void => {
                setEndDate(date);
              }}
              minDate={addDays(startDate, 1)}
              maxDate={now}
            />
          }
        />
        {data && options && !error && !errorOptions && (
          <>
            {data.x.length > 0 && options.yaxis1 && (
              <AxisOptionsSelect
                label='Left Axis'
                unique='left-axis'
                axisOptions={data.y_names.filter(opt => !rightAxisOptions.includes(opt))}
                selectedOptions={leftAxisOptions}
                updateSelectedOptions={setLeftAxisOptions}
              />
            )}
            {data.x.length > 0 && leftAxisOptions && options.yaxis2 && (
              <AxisOptionsSelect
                label='Right Axis'
                unique='right-axis'
                axisOptions={data.y_names.filter(opt => !leftAxisOptions.includes(opt))}
                selectedOptions={rightAxisOptions}
                updateSelectedOptions={setRightAxisOptions}
              />
            )}
            {data.x.length > 0 && (
              <InputSelect
                label='Axis type'
                value={axisType}
                items={AxisTypes}
                updateValue={(data: string): void => setAxisType(data as AxisType)}
              />
            )}
          </>
        )}
      </InputRow>
      {data && options && !error && !errorOptions && (
        <>
          {leftAxisOptions && data.x.length > 0 && (
            <MultiAxisChart
              title='History Chart'
              chartType='Bar'
              axisType={axisType}
              xValues={data.x.map(xValue => moment.parseZone(xValue, moment.ISO_8601))}
              data={getConvertedData(data.y, data.type, state.temperatureMode)}
              allOptions={data.y_names}
              leftAxisOptions={leftAxisOptions}
              rightAxisOptions={rightAxisOptions}
            />
          )}
          {data.x.length === 0 && <Typography variant='sleekHeader'>No Data</Typography>}
        </>
      )}
      {(!data || !options) && !error && !errorOptions && <LoadingIndicator />}
    </Box>
  );
}
