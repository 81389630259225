import React, { useContext, useRef } from 'react';
import { TableRow, TableCell, useTheme, SxProps as MuiSxProps, Chip } from '@mui/material';
import { TreeContext } from '../SearchableTree';
import { compareItems, instanceOfTreeGatewayFE, TreeGatewayFE, TreeGatewayUnassignedFE } from '../../../../model/frontendDataModels';
import { selectGateway } from './selectGateway';
import { useOnScreen } from '../../../../hooks/useOnScreen';
import Store from '../../../../store/Store';
import moment from 'moment-timezone';

type Props = {
  treeGateway?: TreeGatewayFE | TreeGatewayUnassignedFE;
  gatewayList: (TreeGatewayFE | TreeGatewayUnassignedFE)[];
  unassigned: boolean;
};

export default function GatewayTableRow({ treeGateway, gatewayList, unassigned }: Props): JSX.Element | null {
  const theme = useTheme();
  const treeContext = useContext(TreeContext);
  const { state } = useContext(Store);
  const headers = treeGateway === undefined;

  const narrowWidths = unassigned ? ['30%', '30%', '40%'] : ['40%', '40%', '20%'];
  const wideWidths = unassigned ? ['20%', '22%', '18%', '21%', '24%', '24%'] : ['20%', '20%', '20%', '22%', '15%', '15%'];

  const componentRef = useRef<HTMLTableRowElement>(null);

  if (headers) {
    const sx: MuiSxProps = {
      ...theme.typography.columnHeader,
      border: 'none',
      overflow: 'hidden',
      textOverflow: 'clip',
      userSelect: 'none',
    };

    return (
      <TableRow>
        <TableCell width={treeContext.wideMode ? wideWidths[0] : narrowWidths[0]} sx={sx}>
          {'Device ID'}
        </TableCell>
        {treeContext.wideMode && (
          <TableCell width={treeContext.wideMode ? wideWidths[1] : narrowWidths[0]} sx={sx}>
            {'Serial No'}
          </TableCell>
        )}
        <TableCell width={treeContext.wideMode ? wideWidths[2] : narrowWidths[1]} sx={sx}>
          {'Type'}
        </TableCell>
        {treeContext.wideMode && (
          <>
            <TableCell width={wideWidths[3]} sx={sx}>
              {'Last seen'}
            </TableCell>
            {!unassigned && (
              <TableCell width={wideWidths[4]} sx={sx}>
                {'#Nodes'}
              </TableCell>
            )}
          </>
        )}
        <TableCell width={treeContext.wideMode ? wideWidths[5] : narrowWidths[2]} sx={sx}>
          {unassigned ? 'Last seen by' : 'State'}
        </TableCell>
      </TableRow>
    );
  } else {
    const sxBase = theme.typography.deviceInfoText;
    const isSelected = treeContext.selectedTreeItems.findIndex(item => compareItems(item, treeGateway)) !== -1;
    const isOnScreen = useOnScreen(componentRef);
    const lastSeenDate = moment(treeGateway.lastSeen, moment.ISO_8601).tz(state.timezone);
    const lastSeen = lastSeenDate.format('YYYY-MM-DD HH:mm');

    React.useEffect(() => {
      if (componentRef && componentRef.current && isSelected && !isOnScreen) {
        componentRef.current.scrollIntoView({
          behavior: 'smooth',
        });
      }
    }, [treeContext.selectedTreeItems]);

    const sx: MuiSxProps = {
      ...sxBase,
      border: 'none',
      overflow: 'hidden',
      textOverflow: 'clip',
      userSelect: 'none',
      ...(isSelected && {
        backgroundColor: theme.palette.info.main,
        color: theme.palette.action.selected,
      }),
      ...(treeContext.canSelect(treeGateway) && {
        cursor: 'pointer',
      }),
    };

    const isOnline = instanceOfTreeGatewayFE(treeGateway) && treeGateway.state === 'Online';
    const isOffline = instanceOfTreeGatewayFE(treeGateway) && treeGateway.state === 'Offline';
    let updatedBy = treeGateway.updatedBy ?? '';

    updatedBy = updatedBy.toString();

    // Hide email
    if (updatedBy.includes('@')) {
      updatedBy = '';
    }

    const stateSx: MuiSxProps = {
      ...sx,
      color: isOnline ? theme.palette.success.main : theme.palette.error.main,
      ...(isSelected && {
        backgroundColor: theme.palette.info.main,
        color: isOnline ? theme.palette.success.main : theme.palette.error.main,
      }),
    };

    return (
      <TableRow
        ref={componentRef}
        onClick={(event: React.MouseEvent): void => {
          treeContext.canSelect(treeGateway) &&
            selectGateway(treeGateway, treeContext.selectedTreeItems, treeContext.selectTreeItems, gatewayList, event);
        }}
      >
        <TableCell width={treeContext.wideMode ? wideWidths[0] : narrowWidths[0]} sx={sx}>
          {instanceOfTreeGatewayFE(treeGateway) && treeGateway.deviceId ? `${treeGateway.deviceId} ` : ' '}
          {treeGateway.new && <Chip label='new' color='success' variant='outlined' size='small' />}
        </TableCell>
        {treeContext.wideMode && (
          <TableCell width={treeContext.wideMode ? wideWidths[1] : narrowWidths[0]} sx={sx}>
            {treeGateway.serialNumber}
            {unassigned && treeGateway.new && <Chip label='new' color='success' variant='outlined' size='small' />}
          </TableCell>
        )}
        <TableCell width={treeContext.wideMode ? wideWidths[2] : narrowWidths[1]} sx={sx}>
          {'GET Connected'}
        </TableCell>
        {treeContext.wideMode && (
          <>
            <TableCell width={wideWidths[3]} sx={sx}>
              {instanceOfTreeGatewayFE(treeGateway) ? lastSeen : ' '}
            </TableCell>
            {!unassigned && (
              <TableCell width={wideWidths[4]} sx={sx}>
                {instanceOfTreeGatewayFE(treeGateway) ? treeGateway.noOfNodes : ' '}
              </TableCell>
            )}
          </>
        )}
        <TableCell width={treeContext.wideMode ? wideWidths[5] : narrowWidths[2]} sx={unassigned ? sx : stateSx}>
          {unassigned ? updatedBy : isOnline ? 'Online' : isOffline ? 'Offline' : ''}
        </TableCell>
      </TableRow>
    );
  }
}
